import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider } from "semantic-ui-react";
import axios from "axios";
import App from "../../App";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useMyGlobal } from "./GlobalData";
import Cookies from 'js-cookie';
import { Toast } from "./Toast";
import logoHoops from '../assets/logo_hoops.png'

const Login = ({ setShowModal, setLogin, setCart }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [remember, setRemember] = useState(false)
    const ses = window.localStorage
    const [userG, setUserG] = useState([])
    const [profileG, setProfileG] = useState([])
    const [modalSignup, setModalSignup] = useState(false)
    const [profile, setProfile] = useState([])
    const {userData, setUserData} = useMyGlobal()

    const responseMessage = (response) => {
        console.log(response);
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUserG(codeResponse)
            loginGoogle(codeResponse)
        },
        onError: (error) => console.log('Login Failed : ', error)
    })

    const loginGoogle = (user) => { 
        if (user) {
            axios({
                url: `${global.SRV}api_hoops/controler/login.php/?index=2`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: "POST",
                data: user
            })
                .then(res => {
                    if (res.data.state === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Signed in successfully',
                            position : 'center',
                        })
                        createCart(res.data.data.id, res.data.data.token)
                        ses.setItem('loginData', JSON.stringify({
                            id: res.data.data.id,
                            profile: res.data.data.profile,
                            id_cart: res.data.data.cart.id_cart
                        }))
                       
                        setShowModal(false)
                        setLogin(JSON.parse(ses.getItem('loginData')))
                        cartProduct(res.data.data.id, res.data.data.token)
                    } else if (res.data.state === 300) {
                        navigate('/customer/signup', {state:res.data.data})
                        setShowModal(false)
                    } else if (res.data.state === 301) {
                        alert("Email not yet verified by google")
                    }
                })
        }
    }

    const createCart = (customer, token) => {
        const param = new URLSearchParams()
        param.append('id_courier', '')
        param.append('id_customer', customer)
        param.append('id_service', '')
        param.append('total_item', 0)
        param.append('total_trx', 0)
        param.append('total_voucher', 0)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=1`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization' : token
            },
            data: param
        })
            .then((response) => {
                
            })
    }

    const handleChange = () => {
        setRemember(!remember)
    }

    const cartProduct = async (idCustomer, token) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)
  
        await axios({
          method: "POST",
          url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization' : token
          },
          data: param
        })
          .then((response) => { 
            if (response.data.state == 200) {
              setCart(response.data.data)
              if (response.data.data.hasOwnProperty('is_ordered') && response.data.data.is_ordered === '1'){
                navigate('/cart/checkout')
              }
            }
          })
          .catch((error) => console.log(error.response.state))
      }

    const handleLogin = async (e) => {
        e.preventDefault()
        const param = new URLSearchParams()
        if (remember === false) {
            ses.clear()
            setEmail('')
            setPwd('')
            setRemember(false)
        }

        param.append('email', email)
        param.append('pwd', pwd)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/login.php/?index=1`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                
            },
            data: param
        })
            .then((response) => { 
                if (response.data.state === 200) {
                    // Cookies.set('loginData', logDat,{expires : 5 / 1440});
                    Toast.fire({
                        icon: 'success',
                        title: 'Signed in successfully',
                        position:'center'
                        
                    })
                    createCart(response.data.data.id, response.data.data.token)
                    ses.setItem('loginData', JSON.stringify({
                        id: response.data.data.id,
                        token: response.data.data.token,
                        profile: response.data.data.profile,
                        id_cart: response.data.data.cart.id_cart
                    }))
                    setShowModal(false)
                    setLogin(JSON.parse(ses.getItem('loginData')))
                    cartProduct(response.data.data.id, response.data.data.token)
                    // window.location.reload() // GA BAGUS, HARUS PAKE HOOK
                } else if (response.data.state === 500) {
                    alert('Wrong credential')
                } else if (response.data.state === 300){
                    setShowModal(false)
                    navigate("/verification/verificationEmail", {state:{data : response.data, msg:"Your email has been registered, continue to verify.. \n please check your email on inbox or spam folder for lates verification code."}})
                } else if (response.data.state === 400){
                    Toast.fire({
                        icon:'error',
                        title:'Login Error',
                        text: 'The email you entered is not yet registered',
                        position: 'center',
                    })
                }
            })
    }

    useEffect(() => {
        if (ses.getItem('email'))
            setEmail(ses.getItem('email'))

        if (ses.getItem('pwd'))
            setPwd(ses.getItem('pwd'))

        if (ses.getItem('remember')) {
            setRemember(JSON.parse(ses.getItem('remember')))
        }
    }, [])

    useEffect(() => {
        ses.setItem('email', email)
        ses.setItem('pwd', pwd)
        ses.setItem('remember', remember)
    }, [email, pwd, remember])

    
    return (
        <Fragment>
            <div className="text-center">
                <img
                    src={logoHoops}
                    className="rounded"
                    style={{ maxWidth: 120, height: 'auto' }}
                />
            </div>
            <Form className="p-5" onSubmit={(e) => { handleLogin(e) }}>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder="Enter email" size="lg" value={email} onChange={(e) => setEmail(e.target.value)} autoFocus />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" size="lg" value={pwd} onChange={(e) => setPwd(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Row>
                        <Col sm={6} md={6} xs={12} >
                            <Form.Check type="checkbox" label='Remember Me' onChange={handleChange} checked={remember} />
                        <p>{remember}</p>
                        </Col>
                        <Col sm={6} md={6} xs={12} className="text-end">
                            <Link to='/customer/resetPassword' state={{'email' : email}} onClick={() => setShowModal(false)}>Reset password</Link>
                        </Col>
                    </Row>
                </Form.Group>
                <Button
                    fluid
                    color="black"
                    content='Login'
                    icon='right arrow'
                    type="submit"
                    labelPosition="right"
                    size='large'
                />
                <Divider horizontal>Or</Divider>
                <Row className="d-flex justify-content-center mt-3">
                    <Col md={12}>
                        {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} width="385"/> */}
                        <Button color="black" type="button" content="Login with google" onClick={() => login()} fluid size="large" basic icon="google" />
                    </Col>
                </Row>
            </Form>

        </Fragment>
    )
}

export default Login;