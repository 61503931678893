
// import React, { useState } from 'react'
// import { useEffect } from 'react'
// import { Button, Card, Col, Container, Form, FormCheck, InputGroup, ListGroup, Row, Modal, Callout, Alert } from 'react-bootstrap'
// import { Button as SemButton, Icon, Input, Divider, Label } from "semantic-ui-react";
// import moment from "moment/moment";
// import { Link, useNavigate } from 'react-router-dom'
// import Alerts from './Alerts';
// import SummaryCart from "./SummaryCart";
// import axios from "axios";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { useMyGlobal } from './GlobalData';
// import { Toast } from './Toast';


// const Cart = ({ cart, setCart, handleChange }) => {
//   const navigate = useNavigate()
//   const [totalPrice, setTotalPrice] = useState(0)
//   const [totalItem, setTotalItem] = useState(0)
//   const [voucher, setVoucher] = useState(0)
//   const [shipping, setShipping] = useState(0)
//   const [newCart, setNewCart] = useState(null);
//   const [selectedVoucher, setSelectedVoucher] = useState([])
//   const [modalVoucher, setmodalVoucher] = useState(false)
//   const [voucherList, setVoucherList] = useState([])
//   const [voucherGen, setVoucherGen] = useState([])
//   const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
//   const loginData = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')) : null
//   const [codeVoucher, setCodeVoucher] = useState(null)
//   const [btnVoucherDisabled, setBtnVoucherDisabled] = useState(false)
//   const { token, isPortrait, windowWidth } = useMyGlobal()

//   const closeModalmodalVoucher = () => {
//     setmodalVoucher(false)
//     cart.summary.voucher = selectedVoucher.reduce((a, b) => b.group == "SPCV" ? a + parseFloat(b.voucher_value) : 0, 0)
//     cart.summary.voucher_ongkir = selectedVoucher.reduce((a,b) => b.group == "GNRV" ? a + parseFloat(b.nominal) : 0, 0)
//     cart.selected_voucher = selectedVoucher
//     cart.summary.total_order = totalPrice - cart.summary.voucher
//     setCart(cart)

//   }
//   const showModalmodalVoucher = () => (setmodalVoucher(true))

//   const getVoucher = async (total_trx) => {
//     const param = new URLSearchParams()

//     param.append('id_customer', idCustomer)
//     param.append('total_trx', total_trx)

//     await axios({
//       method: "POST",
//       url: `${global.SRV}api_hoops/controler/customer.php/?index=6`,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'authorization': token
//       },
//       data: param
//     })
//       .then((response) => { 
//         setVoucherList(response.data.data)
//       })
//   }

//   const getVoucherGeneral = async (total_trx) => { 
//     const param = new URLSearchParams()
//     console.log(total_trx)
//     param.append('id_customer', idCustomer)
//     param.append('total_trx', total_trx)

//     await axios({
//       method: "POST",
//       url: `${global.SRV}api_hoops/controler/voucher.php/?index=1`,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'authorization': token
//       },
//       data: param
//     })
//       .then((response) => { 
//         setVoucherGen(response.data)
//       })
//   }

//   const calcDiscPercent = (item) => {
//     if (item.discount.length > 0 || item.discount.id_discount && !item.fs_on) {
//       return item.discount.discount_value
//     } else if (item.discount.length === 0 && item.fs_on) {
//       return item.fs_discount
//     } else if (item.discount.length > 0 && item.fs_on) {
//       return item.fs_discount
//     } else if (item.fs_on) {
//       return item.fs_discount
//     } else if (item.discount.length === 0 || item.discount.id_discount === null && !item.fs_on) {
//       return 0
//     } else {
//       return undefined
//     }
//   }

//   const calcDiscValue = (item) => {
//     if (item.discount.length > 0 || item.discount.id_discount && !item.fs_on) {
//       return global.IDR.format(item.discount.discount_total)
//     } else if (item.discount.length === 0 && item.fs_on) {
//       return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
//     } else if (item.discount.length > 0 && item.fs_on) {
//       return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
//     } else if (item.fs_on) {
//       return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
//     } else if (item.discount.length === 0 || item.discount.id_discount === null && !item.fs_on) {
//       return global.IDR.format(0)
//     } else {
//       return undefined
//     }
//   }


//   const applyCodeVoucher = (e) => {
//     e.preventDefault()
//     let exist = false
//     let picked = voucherList.filter(i => i.voucher_code === codeVoucher)[0]

//     if (picked) {
//       if (picked.discount_type == "P") {
//         picked.voucher_value = (picked.discount_percent / 100) * totalPrice
//         cart.summary.voucher = (picked.discount_percent / 100) * totalPrice
//       } else if (picked.discount_type == "N") {
//         picked.voucher_value = parseFloat(picked.discount_value)
//         cart.summary.voucher = parseFloat(picked.discount_value)
//       } else if (voucher.discount_type == "O") {
//         voucher.voucher_value = 0
//         cart.summary.voucher_ongkir = parseFloat(voucher.discount_value)
//       }

//       setCart(cart)

//       exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)

//       if (exist === false) {
//         setSelectedVoucher([...selectedVoucher, picked])
//         closeModalmodalVoucher()
//       }
//     } else {
//       alert('Voucher not exist..')
//       setSelectedVoucher(selectedVoucher.filter(i => i.id_voucher !== voucher.id_voucher))
//       cart.summary.voucher_ongkir = 0
//       setCart(cart)
//     }

//   }

//   const VoucherPicked = (voucher) => { 
//     if (selectedVoucher.some(item => item.id_voucher == voucher.idVoucher) === true) {
//       return <FontAwesomeIcon className="text-dark" icon={faCheck} />
//     } else {
//       return <FontAwesomeIcon className="text-light" icon={faCheck} />
//     }
//   }


//   const handleClickVoucher = (voucher) => { 
//     let exist = false

//     if (voucher.group == "SPCV"){
//       if (voucher.discount_type == "P") {
//         voucher.voucher_value = (voucher.discount_percent / 100) * totalPrice
//         // cart.summary.voucher = (voucher.discount_percent / 100) * totalPrice
//         // cart.summary.voucher_ongkir = 0
//       } else if (voucher.discount_type == "N") {
//         voucher.voucher_value = parseFloat(voucher.discount_value)
//         // cart.summary.voucher = parseFloat(voucher.discount_value)
//         // cart.summary.voucher_ongkir = 0
//       } else if (voucher.discount_type == "O") {
//         voucher.voucher_value = 0
//         // cart.summary.voucher_ongkir = parseFloat(voucher.discount_value)
//       }
//     }

//     if (voucher.group == "GNRV"){
//       if (voucher.type == "N"){
//         voucher.voucher_value = parseFloat(voucher.nominal)
//         // cart.summary.voucher  = parseFloat(voucher.nominal)
//         // cart.summary.voucher_ongkir = 0
//       } else if (voucher.type == "P"){
//         voucher.voucher_value = (voucher.percent / 100) * totalPrice
//         // cart.summary.voucher  = (voucher.percent / 100) * totalPrice
//         // cart.summary.voucher_ongkir = 0
//       } else if (voucher.type == "O"){
//         voucher.voucher_value = 0
//         // cart.summary.voucher_ongkir = parseFloat(voucher.nominal)
//       }
//     }  

//     setCart(cart)

//     exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)

//     if (exist === false) {
//       setSelectedVoucher([...selectedVoucher, voucher])
//       // closeModalmodalVoucher()
//     } else {
//       setSelectedVoucher(selectedVoucher.filter(i => i.id_voucher !== voucher.id_voucher))
//       cart.summary.voucher_ongkir = 0
//       setCart(cart)
//     }
//   }

//   const updateQty = (idCustomer, idCart, idProduct, idColor, size, qty) => {
//     const param = new URLSearchParams()
//     param.append('id_customer', idCustomer)
//     param.append('id_cart', idCart)
//     param.append('id_product', idProduct)
//     param.append('id_color', idColor)
//     param.append('size', size)
//     param.append('qty', qty)
//     axios({
//       method: "POST",
//       url: `${global.SRV}api_hoops/controler/cart.php/?index=4`,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'authorization': token
//       },
//       data: param
//     })
//       .then((response) => {
//         Toast.fire({
//           icon: 'success',
//           title: 'Quantity updated...'
//         })
//       })
//   }

//   const removeFromDb = (id_customer, id_cart, idProduct, idColor, size) => {
//     const param = new URLSearchParams()
//     if (id_cart) {
//       param.append('id_customer', id_customer)
//       param.append('id_cart', id_cart)
//       param.append('id_product', idProduct)
//       param.append('id_color', idColor)
//       param.append('size', size)

//       axios({
//         method: "POST",
//         url: `${global.SRV}api_hoops/controler/cart.php/?index=3`,
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//           'authorization': token
//         },
//         data: param
//       })
//         .then((response) => {

//         })
//     } else {
//       console.log('no id_cart')
//     }
//   }


//   const handleRemove = (i) => {
//     const arr = cart.products.filter((item) => cart.products.indexOf(item) !== i)
//     setCart({ ...cart, products: arr })
//     handleTotal()
//   }

//   const updateCart = (qty, price) => {
//     const param = new URLSearchParams()
//     // let total_weight = cart.products.map((item) => parseFloat(item.product_dimension.weight) * parseInt(item.qty)).reduce((part, a) => part + a, 0)
//     param.append('id_customer', idCustomer)
//     param.append('id_cart', loginData.id_cart)
//     param.append('total_item', qty)
//     param.append('total_trx', price)

//     axios({
//       method: "POST",
//       url: `${global.SRV}api_hoops/controler/cart.php/?index=7`,
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'authorization': token
//       },
//       data: param
//     })
//       .then((response) => {
//         if (response.data.state === 200) {
//           cart.summary.total_weight = response.data.data.total_weight
//         }
//       })
//   }

//   const RenderPriceCoret = ({ data, index }) => {
//     if (data.discount.discount_value > 0 && data.is_discounted == 1 || cart.products[index].fs_on === true) {
//       return <span className='text-muted'><s>{global.IDR.format(data.price)}</s></span>
//     } else {
//       return ''
//     }
//   }

//   const resetVoucher = () => {
//     setSelectedVoucher([])
//     setVoucher(0)
//     setCodeVoucher(null)
//     // setSelectedVoucher([])
//     cart.summary.voucher_ongkir = 0
//   }


//   const handleTotal = () => {
//     let jml = 0
//     let qty = 0

//     cart.products.map((item) => (jml += item.final_price * item.qty))
//     cart.products.map((item) => (qty += item.qty))

//     // updateCart(qty, jml)

//     setTotalPrice(jml)
//     setTotalItem(qty)
//   }

//   const VoucherRender = () => {
//     if (voucherList.length > 0) {
//       return (
//         voucherList.map((item, i) => (
//           <div className="callout" style={{ cursor: 'pointer' }} onClick={() => handleClickVoucher(item)} key={i}>
//             <Row>
//               <Col md={10}>
//                 <div className="fw-bold"><h4>{item.voucher_name}</h4></div>
//                 <div>{item.voucher_desc}</div>
//                 <div className="mt-2">
//                   <span className="me-3" style={{color:'#cc3b21'}}><Icon name='clock outline'/> &nbsp; Will end {moment.utc(item.end_date).local(true).startOf('seconds').fromNow()}</span>
//                   {/* <span><Label>Detail</Label></span> */}
//                 </div>
//               </Col>
//               <Col md={2} className="text-end d-flex align-content-center flex-wrap fs-5 justify-content-md-center">
//                 <VoucherPicked idVoucher={item.id_voucher} />
//               </Col>
//             </Row>
//           </div>
//         ))
//       )
//     } else {
//       return (<div className='d-flex justify-content-center text-muted callout'>Voucher not available</div>)
//     }
//   }

//   const VoucherGeneralRender = () => {
//     if (voucherGen.length > 0) {
//       return (
//         voucherGen.map((item, i) => (
//           <div className="callout" style={{ cursor: 'pointer' }} onClick={() => handleClickVoucher(item)} key={i}>
//             <Row>
//               <Col md={10}>
//                 <div className="fw-bold"><h4>{item.name}</h4></div>
//                 <div>{item.description}</div>
//                 <div className="mt-2">
//                   <span className="me-3" style={{color:'#cc3b21'}}><Icon name='clock outline'/> &nbsp; Will end {moment.utc(item.end_date).local(true).startOf('seconds').fromNow()}</span>
//                   {/* <span><Label>Detail</Label></span> */}
//                 </div>
//               </Col>
//               <Col md={2} className="text-end d-flex align-content-center flex-wrap fs-5 justify-content-md-center">
//                 <VoucherPicked idVoucher={item.id_voucher}  />
//               </Col>
//             </Row>
//           </div>
//         )
//         )
//       )
//     } else {
//       return (<div className='d-flex justify-content-center text-muted callout'>Voucher not available</div>)
//     }
//   }

//   useEffect(() => {
//     updateCart(totalItem, totalPrice)
//   }, [totalItem, totalPrice])

//   useEffect(() => {
//     // localStorage.setItem('cart', JSON.stringify(cart));
//     cart.summary.totalItem = totalItem
//     cart.summary.totalPrice = totalPrice
//     // cart.summary.date = moment().format('YYYY-MM-DD H:m:s')
//   }, [cart, totalItem])

//   useEffect(() => {
//     // const tempCart = JSON.parse(localStorage.getItem('cart'))
//     // if (tempCart) setCart(tempCart)
//     handleTotal()
//   })

//   useEffect(() => { 
//     if (voucherList.length > 0 || voucherGen.length > 0) {
//       setBtnVoucherDisabled(false)
//     } else if (voucherList.length === 0 && voucherGen.length === 0) {
//       setBtnVoucherDisabled(true)
//     }
//   }, [voucherList, voucherGen])

//   useEffect(() => {
//     cart.summary.voucher = selectedVoucher.reduce((a, b) => a + parseFloat(b.voucher_value), 0)
//     cart.summary.voucher_ongkir = selectedVoucher.reduce((a,b) => a + parseFloat(b.nominal), 0)
//     cart.selected_voucher = selectedVoucher
//     cart.summary.total_order = totalPrice - cart.summary.voucher
//     setCart(cart)
//   }, [totalPrice])

//   useEffect(() => {
//     getVoucher(totalPrice)
//     getVoucherGeneral(totalPrice)
//     setSelectedVoucher([])
//   },[totalPrice])

//   useEffect(() => {

//     const total = (data) => {
//       let jml = 0
//       let qty = 0

//       data.products.map((item) => (jml += item.final_price * item.qty))
//       data.products.map((item) => (qty += item.qty))
//       updateCart(qty, jml)
//       setTotalPrice(jml)
//       setTotalItem(qty)
//     }

//     const cartProduct = async (idCustomer) => {
//       const param = new URLSearchParams()
//       param.append('id_customer', idCustomer)

//       await axios({
//         method: "POST",
//         url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//           'authorization': token

//         },
//         data: param
//       })
//         .then((response) => {
//           if (response.data.state == 200) {
//             let data = response.data.data

//             setCart(data)
//             total(data)

//           }
//         })
//         .catch((error) => console.log(error.response.state))
//     }

//     if (JSON.parse(localStorage.getItem('loginData')) !== null) {
//       cartProduct(JSON.parse(localStorage.getItem('loginData')).id)
//     }

//     window.scrollTo(0, 0)

//   }, [])


//   return (
//     <Container style={windowWidth > 1000 && !isPortrait ? { marginTop: '10rem', height: '100vh' } : { marginTop: '6rem', height: '100vh' }}>
//       <section>
//         <Modal show={modalVoucher} onHide={closeModalmodalVoucher} backdrop="static" keyboard={false}>
//           <Modal.Header closeButton><span className='fs-5'>Voucher</span></Modal.Header>
//           <Modal.Body>
//             <Row className="mt-3">
//               <Col>
//                 <Form onSubmit={(e) => applyCodeVoucher(e)}>
//                   <Input action={{color:'black', content:'Apply'}} type="text" placeholder="Enter voucher code" fluid onChange={(e) => setCodeVoucher(e.target.value)}/>
//                 </Form>
//               </Col>
//             </Row>
//             <Divider horizontal>Or</Divider>
//             <h4 className="fw-bold"><Icon name="envelope" />Special Voucher</h4>
//             <VoucherRender  />
//             <h4 className="fw-bold"><Icon name="envelope" />Voucher</h4>
//             <VoucherGeneralRender  />
//             <small>Vouchers can't be combined</small>
//           </Modal.Body>
//           <Modal.Footer>
//             <SemButton color='black' icon="undo" content="Reset" labelPosition='right' onClick={() => { setSelectedVoucher([]); cart.summary.voucher_ongkir = 0 }} disabled={btnVoucherDisabled} />
//             <SemButton color='black' icon="arrow alternate circle right outline" labelPosition='right' content="Apply" onClick={closeModalmodalVoucher} disabled={btnVoucherDisabled} />
//           </Modal.Footer>
//         </Modal>
//       </section>
//       <Row>
//         <div className='text-start'>
//           {
//             (cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) ?
//               <h3 className='mb-3'>Product Cart</h3> :
//               <Alert variant='secondary' className='mb-5'>
//                 This shopping cart has been ordered, proceed to the checkout page or payment page. Click on red <Icon name='cart' color='red' /> cart icon above
//               </Alert>
//           }
//         </div>
//         <Col lg={8} md={8} sm={12} xs={12} className='mb-4' style={windowWidth > 1000 && !isPortrait ? { height: '50vh' } : { height: '25vh' }}>
//           <ListGroup as="ol" numbered>
//             {
//               (cart.products.length > 0) ?
//                 cart.products.map((item, i) => {
//                   return (
//                     <ListGroup.Item
//                       as="li"
//                       className="d-flex justify-content-start align-items-start"
//                       key={i}
//                     >
//                       <Row key="{i}" style={{ width: '100%' }}>
//                         <Col lg={10} md={10} sm={12} xs={12} className='text-start d-flex justify-content-start'>
//                           <div style={{ width: 90, height: 90 }}>
//                             <img src={item.product_image ? item.product_image.image : "https://via.placeholder.com/500x400.png"} alt={item.product_name} className='ms-2 img-fluid rounded' />
//                           </div>
//                           <div className='ms-4'>
//                             <span className='fw-bold'>{item.product_name}</span>
//                             <div>
//                               <span className='me-3 fw-bold'>{global.IDR.format(item.final_price)} </span>
//                               <RenderPriceCoret data={item} index={i} />
//                             </div>
//                             <div>
//                               <span className='me-2'><Label size='mini'>{calcDiscPercent(item)}%</Label></span>
//                               <span><small>{calcDiscValue(item)}</small></span>
//                             </div>
//                             <div>
//                               <span className='me-3 text-muted'>Size : {item.selected_size}</span>
//                               <span className='me-3 text-muted'>Color : </span>
//                               <span>
//                                 <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_main }} key={1} />
//                                 <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_secondary }} key={2} />
//                                 <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_accent }} key={3} />
//                               </span>
//                             </div>
//                           </div>
//                         </Col>
//                         <Col lg={2} md={2} sm={12} xs={12}>
//                           <div className='mt-3 align-items-end'>
//                             {(cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) &&
//                               <InputGroup className="mb-3" key={i}>
//                                 <Button variant="outline" id={i} size='sm' onClick={() => {
//                                   let qty = (item.qty + 1)
//                                   let compareValue = parseInt(item.specific_qty - qty)

//                                   if (compareValue >= 0) {
//                                     resetVoucher()
//                                     handleChange(i, 1)
//                                     updateQty(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size, item.qty)
//                                   } else {
//                                     alert("Insufficient stock")
//                                   }
//                                 }}>
//                                   <Icon name='plus'/>
//                                 </Button>
//                                 <Form.Control
//                                   size='sm'
//                                   value={item.qty}
//                                   className='text-center'
//                                   disabled={true}
//                                 />
//                                 <Button variant='outline' id={i} size='sm' onClick={() => {
//                                   resetVoucher()
//                                   handleChange(i, -1)
//                                   updateQty(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size, item.qty)
//                                 }}>
//                                   <Icon name='minus'/>
//                                 </Button>
//                               </InputGroup>
//                             }
//                           </div>
//                           <div>
//                             {(cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) &&
//                               <SemButton content="Remove" basic icon="trash alternate outline" color="orange" size='mini' onClick={() => {
//                                 handleRemove(i)
//                                 removeFromDb(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size)
//                               }}/>
//                             }
//                           </div>
//                         </Col>
//                       </Row>
//                     </ListGroup.Item>
//                   )
//                 })
//                 :
//                 <>
//                   <Row>
//                     <Col sm={12} md={4} className="text-start">
//                       <SemButton content="Add Product" onClick={() => navigate('/')} />
//                     </Col>
//                   </Row>
//                 </>
//             }
//           </ListGroup>
//         </Col>
//         <Col lg={4} md={4} sm={12} xs={12}>
//           <SummaryCart
//             cart={cart}
//             totalItem={totalItem}
//             totalPrice={totalPrice}
//             voucher={voucher}
//             url={(cart.length < 1) ? null : '/cart/checkout'}
//             label={'Checkout (' + totalItem + ')'}
//             shippingCost={200}
//             setModalPayment={null}
//             customerAddress={null}
//             page='cart'
//             courier={null}
//             showModalmodalVoucher={showModalmodalVoucher}
//             setCart={setCart}
//           />
//         </Col>
//       </Row>
//     </Container>
//   )
// }


// export default Cart

// =========================================================================================== NEW CODE

import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Card, Col, Container, Form, FormCheck, InputGroup, ListGroup, Row, Modal, Callout, Alert, Stack, Image } from 'react-bootstrap'
import { Button as SemButton, Icon, Input, Divider, Label, Popup, Dimmer, Loader } from "semantic-ui-react";
import moment from "moment/moment";
import { Link, useNavigate } from 'react-router-dom'
import SummaryCart from "./SummaryCart";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faL } from '@fortawesome/free-solid-svg-icons';
import { useMyGlobal } from './GlobalData';
import { Toast } from './Toast';
import Roulette from './Roulette';

const Cart = ({ cart, setCart, handleChange }) => {
  const navigate = useNavigate()
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [voucher, setVoucher] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [newCart, setNewCart] = useState(null);
  const [selectedVoucher, setSelectedVoucher] = useState([])
  const [modalVoucher, setmodalVoucher] = useState(false)
  const [voucherList, setVoucherList] = useState([])
  const [voucherListAll, setVoucherListAll] = useState([])
  const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
  const loginData = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')) : null
  const [codeVoucher, setCodeVoucher] = useState(null)
  const [btnVoucherDisabled, setBtnVoucherDisabled] = useState(false)
  const [combine, setCombine] = useState('allow')
  const { token, isPortrait, windowWidth } = useMyGlobal()
  const [showSpinner, setShowSpinner] = useState(false)
  const [inputList, setInputList] = useState([])
  const [rouletteEvent, setRouletteEvent] = useState([])
  const [loaderVoucherGen, setLoaderVoucherGen] = useState(false)
  const [loaderVoucherSpc, setLoaderVoucherSpc] = useState(false)


  const percentTag = {
    alignItems: 'center',
    background: 'linear-gradient(to right, #cc3b21, #FFA500)',
    fontSize: '0.714286rem',
    color: 'rgb(255, 255, 255)',
    fontWeight: 600,
    fontSize: '0.714286rem',
    borderRadius: '0 0 5px 0px',
  }

  const percentTagNormal = {
    alignItems: 'center',
    background: 'linear-gradient(to right, grey, lightgrey)',
    fontSize: '0.714286rem',
    color: 'rgb(255, 255, 255)',
    fontWeight: 600,
    fontSize: '0.714286rem',
    borderRadius: '0 0 5px 0px',
  }

  const closeModalSpinner = () => {
    setShowSpinner(false)
  }

  const closeModalmodalVoucher = () => {
    setmodalVoucher(false)
  }
  const showModalmodalVoucher = () => {
    setmodalVoucher(true)
    getVoucher(idCustomer)
    getVoucherAll(cart.summary.totalPrice, idCustomer)
  }

  const resetVoucher = () => {
    setSelectedVoucher([])
    setVoucher(0)
    setCodeVoucher(null)
    // setSelectedVoucher([])
    cart.summary.voucher_ongkir = 0
  }

  const getVoucherAll = async (total, customer) => {
    const param = new URLSearchParams()
    param.append('total_trx', total)
    param.append('id_customer', customer)
    setLoaderVoucherGen(true)
    await axios({
      method: "POST",
      url: `${global.SRV}api_hoops/controler/voucher.php/?index=2`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token
      },
      data: param
    })
      .then((response) => {
        if (response.data.length > 0) {
          setVoucherListAll(response.data)
        }
        setLoaderVoucherGen(false)
      })

  }

  const getVoucher = async () => {
    const param = new URLSearchParams()

    param.append('id_customer', idCustomer)
    param.append('total_trx', cart.summary.totalPrice)
    setLoaderVoucherSpc(true)
    await axios({ 
      method: "POST",
      url: `${global.SRV}api_hoops/controler/customer.php/?index=6`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token

      },
      data: param
    })
      .then((response) => {
        if (response.data.state === 200) {
          let data = response.data.data

          setVoucherList(data)
        }
        setLoaderVoucherSpc(false)
      })
  }
  
  const applyCodeVoucher = (e) => {
    e.preventDefault()
    let exist = false
    let picked = voucherList.filter(i => i.voucher_code === codeVoucher)[0]
    let pickedGNRV = voucherListAll.filter(i => i.voucher_code === codeVoucher)[0]
    let meetReq = false
    let totalTrx = cart.summary.totalPrice

    if (picked) {
      if (picked.discount_type == "N") {
        picked.voucher_value = parseFloat(picked.discount_value)
      } else if (picked.discount_type == "O") {
        if (totalTrx >= picked.minimum_trx) {
          meetReq = true
          picked.voucher_value = 0
          cart.summary.voucher_ongkir = parseFloat(picked.discount_value)
          setCart(cart)
        } else {
          alert("Vouchers cannot be used, the value of your purchases does not meet")
        }
      }

      exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)

      if (exist === false) {
        if (picked.discount_type === "P") {
          if (totalTrx >= picked.minimum_trx) {
            if (picked.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, picked])
                picked.voucher_value = (picked.discount_percent / 100) * totalPrice
                cart.summary.voucher = picked.voucher_value
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, picked])
              picked.voucher_value = (picked.discount_percent / 100) * totalPrice
              cart.summary.voucher = picked.voucher_value
              setCart(cart)
            }
          }
        } else if (picked.discount_type === "N") {
          if (totalTrx >= picked.minimum_trx) {
            if (picked.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, picked])
                picked.voucher_value = parseFloat(picked.discount_value)
                cart.summary.voucher = picked.voucher_value
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, picked])
              picked.voucher_value = parseFloat(picked.discount_value)
              cart.summary.voucher = picked.voucher_value
              setCart(cart)
            }
          }
        } else if (picked.discount_type === "O") {
          if (totalTrx >= picked.minimum_trx) {
            if (picked.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, picked])
                picked.voucher_value = 0
                cart.summary.voucher_ongkir = parseFloat(picked.discount_value)
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, picked])
              picked.voucher_value = parseFloat(picked.discount_value)
              cart.summary.voucher = picked.voucher_value
              setCart(cart)
            }
          }
        }
      }
    } else if (pickedGNRV) {     // -------------GENERAL VOUCHER-----------------
      if (pickedGNRV.type == "N") {
        pickedGNRV.voucher_value = parseFloat(pickedGNRV.nominal)
      } else if (pickedGNRV.type == "O") {
        if (totalTrx >= pickedGNRV.minimum_trx) {
          meetReq = true
          pickedGNRV.voucher_value = 0
          cart.summary.voucher_ongkir = parseFloat(pickedGNRV.nominal)
          setCart(cart)
        } else {
          alert("Vouchers cannot be used, the value of your purchases does not meet")
        }
      }

      exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)
      
      if (exist === false) { 
        if (pickedGNRV.type === "D") { 
          if (totalTrx >= pickedGNRV.minimum_trx) {
            if (pickedGNRV.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, pickedGNRV])
                pickedGNRV.voucher_value = (pickedGNRV.percent / 100) * totalPrice
                cart.summary.voucher = pickedGNRV.voucher_value
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, pickedGNRV])
              pickedGNRV.voucher_value = (pickedGNRV.percent / 100) * totalPrice
              cart.summary.voucher = pickedGNRV.voucher_value
              setCart(cart)
            }
          }
        } else if (pickedGNRV.type === "N") {
          if (totalTrx >= pickedGNRV.minimum_trx) {
            if (pickedGNRV.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, pickedGNRV])
                pickedGNRV.voucher_value = parseFloat(pickedGNRV.nominal)
                cart.summary.voucher = pickedGNRV.voucher_value
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, pickedGNRV])
              pickedGNRV.voucher_value = parseFloat(pickedGNRV.nominal)
              cart.summary.voucher = pickedGNRV.voucher_value
              setCart(cart)
            }
          }
        } else if (pickedGNRV.type === "O") {
          if (totalTrx >= pickedGNRV.minimum_trx) {
            if (pickedGNRV.is_combine === 'N') {
              if (selectedVoucher.length == 0) {
                setSelectedVoucher([...selectedVoucher, pickedGNRV])
                pickedGNRV.voucher_value = 0
                cart.summary.voucher_ongkir = parseFloat(pickedGNRV.nominal)
                setCart(cart)
              } else {
                alert('This voucher cannot be combined with other vouchers')
              }
            } else if (voucher.is_combine === 'Y') {
              setSelectedVoucher([...selectedVoucher, pickedGNRV])
              pickedGNRV.voucher_value = parseFloat(pickedGNRV.nominal)
              cart.summary.voucher = pickedGNRV.voucher_value
              setCart(cart)
            }
          }
        }
      }
      // closeModalmodalVoucher()
    } else {
      Toast.fire({
        icon: 'warning',
        text: 'Voucher Code not Exist..',
        position: 'top-right'
      })
    }


  }

  const VoucherPicked = (voucher) => {
    if (selectedVoucher.some(item => item.id_voucher == voucher.idVoucher) === true) {
      return <FontAwesomeIcon className="text-muted" icon={faCheck} />
    } else {
      return <FontAwesomeIcon className="text-light" icon={faCheck} />
    }
  }


  const handleClickVoucher = (voucher) => {
    let exist = false
    let isCombine = false
    let totalTrx = cart.summary.totalPrice
    let meetReq = false

    if (voucher.group === 'SPCV') {
      exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)
      isCombine = selectedVoucher.some(i => i.is_combine === 'N')

      exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)
      if (!exist) {
        if (!isCombine) {
          if (voucher.discount_type === "O") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  voucher.voucher_value = 0
                  cart.summary.voucher_ongkir = parseFloat(voucher.discount_value)
                  setCart(cart)
                } else {
                  alert('This voucher cannot be combined with other vouchers')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                voucher.voucher_value = 0
                cart.summary.voucher_ongkir = parseFloat(voucher.discount_value)
                setCart(cart)
              }
            } else {
              alert("Your transaction amount has not met the requirements")
            }
          } else if (voucher.discount_type === "N") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  voucher.voucher_value = parseFloat(voucher.discount_value)
                } else {
                  alert('This voucher cannot be combined with other vouchers')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                voucher.voucher_value = parseFloat(voucher.discount_value)
              }
            } else {
              alert("Your transaction amount has not met the requirements")
            }
          } else if (voucher.discount_type === "P") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  voucher.voucher_value = (voucher.discount_percent / 100) * totalPrice
                } else {
                  alert('This voucher cannot be combined with other vouchers')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                voucher.voucher_value = (voucher.discount_percent / 100) * totalPrice
              }
            } else {
              alert("Your transaction amount has not met the requirements")
            }
          }
        } else {
          alert('Can`t combine this voucher with other')
        }
      } else {
        setSelectedVoucher(selectedVoucher.filter(i => i.id_voucher !== voucher.id_voucher))
        cart.summary.voucher_ongkir = 0
        setCart(cart)
      }
      // ==============================================================
    } else if (voucher.group === 'GNRV') {
      exist = selectedVoucher.some(i => i.id_voucher == voucher.id_voucher)
      isCombine = selectedVoucher.some(i => i.is_combine === 'N')

      if (exist === false) {
        if (!isCombine) {
          if (voucher.type === "O") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  voucher.voucher_value = 0
                  cart.summary.voucher_ongkir = parseFloat(voucher.nominal)
                  setCart(cart)
                } else if (selectedVoucher.length > 0) {
                  alert('This voucher can`t combine this voucher with other')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                voucher.voucher_value = 0
                cart.summary.voucher_ongkir = parseFloat(voucher.nominal)
                setCart(cart)
              }
            } else {
              alert("Vouchers cannot be used, the value of your purchases does not meet")
            }
          } else if (voucher.type === "D") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  if (voucher.is_specific_product === 'Y') {
                    voucher.voucher_value = voucher.total_discount
                  } else {
                    voucher.voucher_value = (voucher.percent / 100) * totalPrice
                  }
                } else if (selectedVoucher.length > 0) {
                  alert('This voucher can`t combine this voucher with other')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                if (voucher.is_specific_product === 'Y') {
                  voucher.voucher_value = voucher.total_discount
                } else {
                  voucher.voucher_value = (voucher.percent / 100) * totalPrice
                }
              }
            } else {
              alert("Vouchers cannot be used, the value of your purchases does not meet")
            }
          } else if (voucher.type === "N") {
            if (totalTrx >= voucher.minimum_trx) {
              if (voucher.is_combine === 'N') {
                if (selectedVoucher.length == 0) {
                  setSelectedVoucher([...selectedVoucher, voucher])
                  voucher.voucher_value = parseFloat(voucher.nominal)
                } else if (selectedVoucher.length > 0) {
                  alert('This voucher can`t combine this voucher with other')
                }
              } else if (voucher.is_combine === 'Y') {
                setSelectedVoucher([...selectedVoucher, voucher])
                voucher.voucher_value = parseFloat(voucher.nominal)
              }
            } else {
              alert("Vouchers cannot be used, the value of your purchases does not meet")
            }
          }
        } else {
          alert('Can`t combine this voucher with other')
        }
      } else {
        setSelectedVoucher(selectedVoucher.filter(i => i.id_voucher !== voucher.id_voucher))
        cart.summary.voucher_ongkir = 0
        setCart(cart)
      }
    }
  }

  const updateQty = (idCustomer, idCart, idProduct, idColor, size, qty, price) => {
    const param = new URLSearchParams()
    param.append('id_customer', idCustomer)
    param.append('id_cart', idCart)
    param.append('id_product', idProduct)
    param.append('id_color', idColor)
    param.append('size', size)
    param.append('qty', qty)
    axios({
      method: "POST",
      url: `${global.SRV}api_hoops/controler/cart.php/?index=4`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token

      },
      data: param
    })
      .then((response) => {
        handleTotal()
        Toast.fire({
          icon: 'success',
          title: 'Quantity updated...'
        })
      })
  }

  const removeFromDb = (id_customer, id_cart, idProduct, idColor, size) => {
    const param = new URLSearchParams()
    if (id_cart) {
      param.append('id_customer', id_customer)
      param.append('id_cart', id_cart)
      param.append('id_product', idProduct)
      param.append('id_color', idColor)
      param.append('size', size)

      axios({
        method: "POST",
        url: `${global.SRV}api_hoops/controler/cart.php/?index=3`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token

        },
        data: param
      })
        .then((response) => {
          console.log(response.data)
        })
    } else {
      console.log('no id_cart')
    }
  }

  const calcDiscPercent = (item) => {
    if (item.discount.length > 0 || item.discount.id_discount && !item.fs_on) {
      return <Label size="mini" style={percentTag}>{Math.ceil(item.discount.discount_value)}%</Label>
    } else if (item.discount.length === 0 && item.fs_on) {
      return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
    } else if (item.discount.length > 0 && item.fs_on) {
      return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
    } else if (item.fs_on) {
      return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
    } else if (item.discount.length === 0 || item.discount.id_discount === null && !item.fs_on) {
      return <Label size='mini' style={percentTagNormal}>No Dscn.</Label>
    } else {
      return undefined
    }
  }

  const calcDiscValue = (item) => {
    if (item.discount.length > 0 || item.discount.id_discount && !item.fs_on) {
      return global.IDR.format(item.discount.discount_total)
    } else if (item.discount.length === 0 && item.fs_on) {
      return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
    } else if (item.discount.length > 0 && item.fs_on) {
      return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
    } else if (item.fs_on) {
      return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
    } else if (item.discount.length === 0 || item.discount.id_discount === null && !item.fs_on) {
      return global.IDR.format(0)
    } else {
      return undefined
    }
  }

  const handleRemove = (i) => {
    const arr = cart.products.filter((item) => cart.products.indexOf(item) !== i)
    setCart({ ...cart, products: arr })
  }

  const updateCart = (qty, price) => {
    const param = new URLSearchParams()
    // let total_weight = cart.products.map((item) => parseFloat(item.product_dimension.weight) * parseInt(item.qty)).reduce((part, a) => part + a, 0)
    param.append('id_customer', idCustomer)
    param.append('id_cart', loginData.id_cart)
    param.append('total_item', qty)
    param.append('total_trx', price)

    axios({
      method: "POST",
      url: `${global.SRV}api_hoops/controler/cart.php/?index=7`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token

      },
      data: param
    })
      .then((response) => {
        if (response.data.state === 200) {
          cart.summary.total_weight = response.data.data.total_weight
        }
      })
  }

  const VoucherCodeUsed = () => {
    if (selectedVoucher.length > 0) {
      return (
        selectedVoucher.map(item => (
          item.show === 'N' &&
          <Row className='pt-3 ms-1 me-1'>
            <div className='p-3' style={{ border: 'dashed 2px coral', borderRadius: '0.5rem', padding: '6px', backgroundColor: 'whitesmoke' }}>
              <Stack direction='vertical' gap={0}>
                <div className='fw-bold'><Icon name='ticket alternate' color='orange' />&nbsp;{item.voucher_name || item.name}</div>
                <div className='text-muted mb-2'>{item.voucher_desc || item.description}</div>
                <div className='fw-bold'>Rp {item.voucher_value}</div>
                <div className="mt-2">
                  <span className="me-3" style={{ color: '#cc3b21' }}><Icon name='clock outline' /> &nbsp; Will end {moment.utc(item.end_date).local(true).startOf('seconds').fromNow()}</span>
                </div>
              </Stack>
            </div>
          </Row>
        ))

      )
    }
  }

  const handleTotal = () => {
    // let jml = 0
    // let qty = 0
    let jml = cart.products.reduce((total, cur) => total + cur.final_price * cur.qty, 0)
    let qty = cart.products.reduce((total, cur) => total + cur.qty, 0)
    // cart.products.map((item) => (jml += item.final_price * item.qty))
    // cart.products.map((item) => (qty += item.qty))
    updateCart(qty, jml)

    setTotalPrice(jml)
    setTotalItem(qty)
  }

  const handleClickName = (id_product) => {
    navigate(`/product/${id_product}`)
  }

  const RenderPriceCoret = ({ data, index }) => {
    if (data.discount.discount_value > 0 && data.is_discounted == 1 || cart.products[index].fs_on === true) {
      return <span className='text-muted'><s>{global.IDR.format(data.price)}</s></span>
    } else {
      return ''
    }
  }

  const handleClickWheel = () => {
    if (inputList.length > 0) {
      setShowSpinner(true)
    } else {
      console.log("loading..")
    }
  }

  const getPrize = async () => {
    await axios({
      method: "GET",
      url: `${global.SRV}api_hoops/controler/voucher.php/?index=4`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token

      },
    })
      .then(res => {
        if (res.data.state === 200) {
          setInputList(res.data.data)
        }
      })
  }

  useEffect(() => {
    // localStorage.setItem('cart', JSON.stringify(cart));
    cart.summary.totalItem = totalItem
    cart.summary.totalPrice = totalPrice
    // cart.summary.date = moment().format('YYYY-MM-DD H:m:s')

    const updateChance = (data, val) => {
      const param = new URLSearchParams()
      param.append('id_cart', data.summary.id_cart)
      param.append('id_customer', data.customer.id_customer)
      param.append('val', val)
      axios({
        method: "POST",
        url: `${global.SRV}api_hoops/controler/cart.php/?index=15`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token
        },
        data: param
      })
        .then(res => {
          if (res.data.state === 200) {

          }
        })
    }

    const getRouletteEvent = async (trx) => {
      await axios({
        method: "GET",
        url: `${global.SRV}api_hoops/controler/event.php/?index=7&trx=${trx}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token
        },
      })
        .then(res => {
          if (res.data.state === 200) {
            setRouletteEvent(res.data.data)
            if (res.data.data.length > 0) {
              if (cart.is_played === 'N' && (parseInt(cart.roulette_chance) < 1 || cart.roulette_chance == null)) {
                let min_trx = parseInt(res.data.data[0].minimum_trx)
                if (cart.summary.totalPrice >= min_trx) {
                  updateChance(cart, 1)
                } else if (cart.summary.totalPrice < min_trx) {
                  updateChance(cart, 0)
                }
              }
            }
          }
        })
    }

    getRouletteEvent(cart.summary.totalPrice)
  }, [cart, totalItem])

  useEffect(() => {
    if (voucherList.length > 0 || voucherListAll.length > 0) {
      setBtnVoucherDisabled(false)
    } else if (voucherList.length === 0 || voucherListAll.length === 0) {
      setBtnVoucherDisabled(true)
    }
  }, [voucherList, voucherListAll])

  useEffect(() => {
    // getVoucher()
    cart.summary.voucher = selectedVoucher.reduce((a, b) => a + b.voucher_value, 0)
    cart.selected_voucher = selectedVoucher
    cart.summary.total_order = cart.summary.totalPrice - cart.summary.voucher
    setCart(cart)
  }, [selectedVoucher])

  useEffect(() => {

    const total = (data) => {
      let jml = 0
      let qty = 0

      data.products.map((item) => (jml += item.final_price * item.qty))
      data.products.map((item) => (qty += item.qty))
      updateCart(qty, jml)
      setTotalPrice(jml)
      setTotalItem(qty)
    }


    const cartProduct = async (idCustomer) => {
      const param = new URLSearchParams()
      param.append('id_customer', idCustomer)

      await axios({
        method: "POST",
        url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token

        },
        data: param
      })
        .then((response) => {
          if (response.data.state == 200) {
            let data = response.data.data

            setCart(data)
            total(data)

          }
        })
        .catch((error) => console.log(error.response.state))
    }

    if (JSON.parse(localStorage.getItem('loginData')) !== null) {
      cartProduct(JSON.parse(localStorage.getItem('loginData')).id)
      getPrize()
    }

    window.scrollTo(0, 0)

  }, [])

  useEffect(() => {
    if (selectedVoucher.length == 1) {
      cart.summary.voucher = selectedVoucher[0].voucher_value
      cart.summary.voucher_ongkir = (selectedVoucher[0].type === 'O' || selectedVoucher[0].dicount_type === 'O') ? selectedVoucher[0].nominal : 0
      setCart(cart)
    }
  }, [cart.summary.voucher, cart.summary.voucher_ongkir])

  useEffect(() => {
    handleTotal()

    if (cart.products.length === 0){
      setSelectedVoucher([])
    }

    if (selectedVoucher.length > 0 && cart.products.length > 0){
      setSelectedVoucher([])
      Toast.fire({icon:'info', text:"The voucher you chose has been reset, because you changed the product in your basket, but don't worry, you can re-add your voucher"})
    }
  },[cart.products])
  console.log(cart)
  // console.log(cart.products.map((item) => parseFloat(item.product_dimension.weight) * parseInt(item.qty)).reduce((part, a) => part + a, 0))

  return (
    <>
      <Container style={windowWidth > 1000 && !isPortrait ? { marginTop: '8rem', height: '100vh' } : { marginTop: '6rem', height: '100vh' }}>
        <section >
          <Modal show={modalVoucher} onHide={closeModalmodalVoucher} backdrop="static" keyboard={false} >
            <Modal.Header closeButton><span className='fs-5'>Use Voucher</span></Modal.Header>
            <Modal.Body >
              <Dimmer active={loaderVoucherSpc && loaderVoucherGen}>
                <Loader>Loading voucher..</Loader>
              </Dimmer>
              <Row className="mt-3">
                <Col>
                  <Form onSubmit={(e) => applyCodeVoucher(e)}>
                    <Input action={{ color: 'black', content: 'Apply Code' }} type="text" placeholder="Enter voucher code" fluid onChange={(e) => setCodeVoucher(e.target.value)} />
                  </Form>
                </Col>
              </Row>
              <VoucherCodeUsed />
              <Divider horizontal>Or</Divider>
              <h5><Icon name="envelope outline" className='me-2' />Special Voucher</h5>
              {
                (voucherList.length > 0) ?
                  voucherList.map((item, i) => {
                    const existOn = selectedVoucher.some((o, i) => (o.id_voucher === item.id_voucher))

                    if (item.show == 'Y') {
                      return (
                        <div className="callout" style={existOn ? { borderColor: 'coral', cursor: 'pointer' } : { cursor: 'pointer' }} onClick={() => handleClickVoucher(item)} key={i}>
                          <Row>
                            <Col md={10}>
                              <div className=""><h5><Icon name='ticket alternate' color='orange' />&nbsp;{item.voucher_name}</h5></div>
                              <div className='text-muted'>{item.voucher_desc}</div>
                              <div className='fw-bold mt-2'>{item.discount_value > 0 ? global.IDR.format(item.discount_value) : item.discount_percent + '%'}</div>
                              <div className="mt-2">
                                <span className="me-3" style={{ color: '#cc3b21' }}><Icon name='clock outline' /> &nbsp; Will end {moment.utc(item.end_date).local(true).startOf('seconds').fromNow()}</span>
                              </div>
                            </Col>
                            <Col md={2} className="text-end d-flex align-content-center flex-wrap fs-5 justify-content-md-center">
                              <VoucherPicked idVoucher={item.id_voucher} className="" />
                            </Col>
                          </Row>
                        </div>
                      )
                    }
                  }
                  )
                  :
                  <div className='mt-1 mb-1 text-center text-muted'>There are currently no special vouchers</div>
              }
              <h5><Icon name="envelope outline" className='me-2' />Voucher</h5>
              {
                (voucherListAll.length > 0) ?
                  voucherListAll.map((item, i) => {
                    const existOn = selectedVoucher.some((o, i) => (o.id_voucher === item.id_voucher))
                    if (item.show === 'Y') {
                      return (
                        <div className="callout" style={existOn ? { cursor: 'pointer', borderColor: 'coral' } : { cursor: 'pointer' }} onClick={() => handleClickVoucher(item)} key={i}>
                          <Row>
                            <Col md={10}>
                              <div className=""><h5><Icon name='ticket alternate' color='orange' />&nbsp;{item.name}</h5></div>
                              <div className='text-muted'>{item.description}</div>
                              <div className='fw-bold mt-2'>{item.nominal > 0 ? global.IDR.format(item.nominal) : item.percent + '%'}</div>
                              <div className="mt-2">
                                <span className="me-3" style={{ color: '#cc3b21' }}><Icon name='clock outline' /> &nbsp; Will end {moment.utc(item.end_date).local(true).startOf('seconds').fromNow()}</span>
                              </div>
                            </Col>
                            <Col md={2} className="text-end d-flex align-content-center flex-wrap fs-5 justify-content-md-center">
                              <VoucherPicked idVoucher={item.id_voucher} className="" />
                            </Col>
                          </Row>
                        </div>
                      )
                    } else {
                      <></>
                    }
                  }
                  )
                  :
                  <div className='text-center text-muted'>There are currently no vouchers</div>
              }
            </Modal.Body>
            <Modal.Footer >
              <SemButton className='mb-3' content="Apply Selected Voucher" icon="arrow alternate circle right outline" labelPosition='right' color='black' fluid onClick={closeModalmodalVoucher} disabled={btnVoucherDisabled} />
              <SemButton className='mb-3' content="Reset" labelPosition='right' color='black' icon="undo" fluid onClick={() => { setSelectedVoucher([]); cart.summary.voucher_ongkir = 0; cart.summary.voucher = 0 }} disabled={btnVoucherDisabled} />
            </Modal.Footer>
          </Modal>
        </section>
        <Row>
          <div className='text-start'>
            {
              (cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) ?
                <h3 className='mb-3'>Product Cart</h3> :
                <Alert variant='secondary' className='mb-5'>
                  This shopping cart has been ordered, proceed to the checkout page or payment page. Click on red <Icon name='cart' color='red' /> cart icon above              </Alert>
            }
          </div>
          <Col lg={8} md={8} sm={12} xs={12} className='mb-4'>
            <ListGroup as="ol" numbered>
              {
                (cart.products.length > 0) ?
                  cart.products.map((item, i) => {
                    return (
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-start pt-4"
                        key={i}
                      >
                        <Row key="{i}" style={{ width: '100%' }} className={windowWidth < 1000 ? 'mb-3' : ''}>
                          <Col lg={10} md={10} sm={12} xs={12} className={windowWidth < 1000 ? 'text-start d-flex justify-content-start mb-4' : 'text-start d-flex justify-content-start'}>
                            <div style={{ width: 90, height: 90 }}>
                              <Image src={item.product_image ? item.product_image.image : 'https://via.placeholder.com/500x400.png'} alt={item.product_name} className='ms-2 img-fluid border' rounded />
                            </div>
                            <div className='ms-4'>
                              <div>{item.product_id}</div>
                              <span className='fw-bold' style={{ cursor: 'pointer', color: '#ff5733' }} onClick={() => handleClickName(item.product_id)}>{item.product_name}</span>
                              <div>
                                <span className='me-3 fw-bold'>{global.IDR.format(item.final_price)} </span>
                                <RenderPriceCoret data={item} index={i} />
                              </div>
                              <div>
                                {calcDiscPercent(item)}
                                {/* <span><small>{calcDiscValue(item)}</small></span> */}
                              </div>
                              <div>
                                <span className='me-3 text-muted'>Size : {item.selected_size}</span>
                                <span className='me-3 text-muted'>Color : </span>
                                <span>
                                  <canvas className='border-start border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_main }} key={1} />
                                  <canvas className='border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_secondary }} key={2} />
                                  <canvas className='border-end border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_accent }} key={3} />
                                </span>
                              </div>
                              <div>
                                {
                                  item.included_on_voucher &&
                                  item.included_on_voucher.map((item, i) => (
                                    <Popup trigger={
                                      <div className='fw-bold' style={{ color: '#F7634C', fontSize: '0.8em' }}>
                                        <Icon name='tag' />
                                        {item.name} -{item.type == 'D' ? item.percent : item.nominal}% off
                                      </div>
                                    }
                                      size='mini'
                                      content={`This item is included in the voucher ${item.name}`}
                                    />
                                  ))
                                }
                              </div>
                            </div>
                          </Col>
                          <Col lg={2} md={2} sm={12} xs={12} >
                            <div className='align-items-end'>
                              {(cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) &&
                                <InputGroup className="mb-3" key={i}>
                                  <Button variant="outline-dark" id={i} size='sm' onClick={() => {
                                    resetVoucher()
                                    handleChange(i, -1)
                                    updateQty(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size, item.qty)
                                  }}>
                                    -
                                  </Button>
                                  <Form.Control
                                    size='sm'
                                    value={item.qty}
                                    className='text-center'
                                    disabled={true}
                                  />

                                  <Button variant="outline-dark" id={i} size='sm' onClick={() => {
                                    let qty = (item.qty + 1)
                                    let compareValue = parseInt(item.specific_qty - qty)

                                    if (compareValue >= 0) {
                                      resetVoucher()
                                      handleChange(i, 1)
                                      updateQty(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size, item.qty)
                                    } else {
                                      alert("Insufficient stock")
                                    }
                                  }}>
                                    +
                                  </Button>
                                </InputGroup>
                              }
                            </div>
                            <div>
                              {(cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) &&
                                <SemButton fluid content="Remove" basic icon="trash alternate outline" color="orange" size='mini' onClick={() => {
                                  handleRemove(i)
                                  removeFromDb(idCustomer, loginData.id_cart, item.product_id, item.selected_color.id_color, item.selected_size)
                                }} />
                              }
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  })
                  :
                  <>
                    <Row>
                      <Col sm={12} md={12} lg={12} xl={12} className="text-start">
                        <p>You haven't added any products to your cart, please select your product</p>
                        <SemButton content="Add Product" onClick={() => navigate('/')} color="orange" icon="add" />
                      </Col>
                    </Row>
                  </>
              }
            </ListGroup>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <SummaryCart
              cart={cart}
              totalItem={totalItem}
              totalPrice={totalPrice}
              voucher={voucher}
              url={(cart.length < 1) ? null : '/cart/checkout'}
              label={'Checkout (' + totalItem + ')'}
              shippingCost={200}
              setModalPayment={null}
              customerAddress={null}
              page='cart'
              courier={null}
              showModalmodalVoucher={showModalmodalVoucher}
              setCart={setCart}
            />
          </Col>
        </Row>
      </Container >
      {
        rouletteEvent.length > 0 ?
          <>
            <Modal show={showSpinner} onHide={closeModalSpinner} centered backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{rouletteEvent[0].roulette_name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Roulette data={inputList} cart={cart} setCart={setCart} />
              </Modal.Body>
            </Modal>
            <div className='floating-container'>
              <Popup
                content={rouletteEvent[0].roulette_name}
                trigger={
                  <SemButton color='black' circular className='floating-btn' onClick={() => handleClickWheel()} icon={{ name: 'box', size: 'big' }} />
                }
              />
            </div>
          </>
          : <></>
      }
    </>
  )
}


export default Cart
